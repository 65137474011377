<template>
  <app-layout>
    <transition name="component-fade" mode="out-in">
      <div
        v-if="showServiceForm"
        class="flex flex-col items-center py-4"
        key="form">
        <div class="w-full lg:w-1/2">
          <div class="flex flex-row items-center inline py-4">
            <sa-icon-button
              iconClass="bx bx-left-arrow-alt"
              @click="closeServiceForm"
              with-bg />
            <span class="mx-2 text-lg font-bold">Crear servicio</span>
          </div>
        </div>
      </div>
      <div
        v-if="!showServiceForm"
        class="flex flex-col items-center py-4"
        key="list">
        <div class="w-full px-12">
          <div class="flex flex-row items-center py-4">
            <h3 class="text-lg font-bold">Servicios</h3>
            <div class="ml-auto">
              <sa-button
                type="primary"
                class="items-center px-3 py-3 font-bold rounded"
                @click="createService">
                Crear servicio
              </sa-button>
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <content-card>
              <services-list @onEdit="updateService" :onCreated="onCreated" />
            </content-card>
          </div>
        </div>
      </div>
    </transition>
  </app-layout>
</template>
<script>
import CategoryDialogService, { command as CategoryDialogCommand, commands } from '@/components/dialogs/Category/service';
import CategoryEnum from '@/components/dialogs/Category/enum';

export default {
  name: 'ServicesIndex',
  components: {
    ServicesList: () => import('./ServicesList.vue'),
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
  },
  data() {
    return {
      showServiceForm: false,
      isNewService: false,
      serviceToUpdate: null,
      CategoryEnum,
      onCreated: null,
      subscription: null,
    };
  },
  created() {
    this.subscription = CategoryDialogCommand.subscribe(this.customCommands);
  },
  destroyed() {
    this.subscription.unsubscribe();
  },
  methods: {
    customCommands({ type, payload }) {
      switch (type) {
        case commands.create:
          this.createCategory(payload);
          break;
        default:
      }
    },
    savedService() {
      this.showServiceForm = false;
      this.$message({
        customClass: 'text-gray-700',
        message: 'El servicio se guardó correctamente' });
    },
    updateService(serviceToUpdate) {
      this.serviceToUpdate = serviceToUpdate;
      this.showServiceForm = true;
    },
    createService() {
      if (this.$store.state.business.id) {
        CategoryDialogService.show({ type: CategoryEnum.Service });
      } else {
        this.$toastr.i("Tienes que estar en una sucursal para poder crear servicios");
      }
    },
    closeServiceForm() {
      this.showServiceForm = false;
      this.isNewService = false;
      this.serviceToUpdate = null;
    },
    createCategory(category) {
      this.onCreated = category;
    },
  },
}
</script>

<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .2s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
